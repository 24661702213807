export const CHANGE_ARTICLE_CATEGORY = 'CHANGE_ARTICLE_CATEGORY'
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES'
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES'
export const TOGGLE_ARTICLE_VISIBILITY = 'TOGGLE_ARTICLE_VISIBILITY'

export function setCategory(categoryId) {
	return {
		type: CHANGE_ARTICLE_CATEGORY,
		categoryId,
	}
}

export function updateCategoryList(categories) {
	return {
		type: UPDATE_CATEGORIES,
		categories,
	}
}

export function updateArticleList(articles) {
	return {
		type: UPDATE_ARTICLES,
		articles,
	}
}

export function toggleArticleVisibility(articleId) {
	return {
		type: TOGGLE_ARTICLE_VISIBILITY,
		articleId,
	}
}
