/**
 * Actions
 */
export const CHANGE_ACTIVE_PAGE = 'CHANGE_ACTIVE_PAGE'

/**
 * Pages
 */
export const ARTICLES_PAGE = 'ARTICLES_PAGE'
export const MEMBERS_PAGE = 'MEMBERS_PAGE'
export const LINKS_PAGE = 'LINKS_PAGE'
export const CONTACTS_PAGE = 'CONTACTS_PAGE'
export const BOARD_PAGE = 'BOARD_PAGE'

/**
 *
 */
export function changeActivePage(page) {
	return {
		type: CHANGE_ACTIVE_PAGE,
		page,
	}
}
