const monthNames = [
	'januar',
	'februar',
	'mars',
	'april',
	'mai',
	'juni',
	'juli',
	'august',
	'september',
	'oktober',
	'november',
	'desember',
]

const dayNames = [
	'søndag',
	'mandag',
	'tirsdag',
	'onsdag',
	'torsdag',
	'fredag',
	'lørdag',
]

export function formatDate(date) {
	const dateString = date.replace(' ', 'T') // the date from the server is not correctly formatted
	const dateObj = new Date(dateString)
	const day = dateObj.getDate()
	const monthIndex = dateObj.getMonth()
	const dayOfWeekIndex = dateObj.getDay()
	const year = dateObj.getFullYear()

	return `${dayNames[dayOfWeekIndex]} ${day}. ${monthNames[monthIndex]} ${year}`
}
