import React, { Component } from 'react'

import load from '../services/load'

import Categories from './Categories.jsx'
import Article from './Article.jsx'

export default class ArticlesPage extends Component {
	componentDidMount() {
		const { actions, allArticles } = this.props

		if (allArticles.length > 0) {
			return
		}

		load('articles')
			.then(result => {
				actions.articles.updateCategoryList(result.categories)
				actions.articles.updateArticleList(result.articles)
			})
			.catch(err => {
				console.log('ERROR', err)
				actions.error.setErrorMessage(
					'Beklager. Det skjedde en feil når artikklene skulle lastes inn',
				)
			})
	}

	render() {
		const {
			articleList,
			categoryList,
			categoryId,
			actions,
			numArticlesInCategory,
		} = this.props

		return (
			<section className="information">
				<Categories
					categories={categoryList}
					category={categoryId}
					actions={actions}
					numArticlesInCategory={numArticlesInCategory}
				/>
				{articleList.map((article, index) => {
					return (
						<Article
							key={article.id}
							{...article}
							actions={actions}
							index={index}
						/>
					)
				})}
			</section>
		)
	}
}
