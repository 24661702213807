import React, { Component } from 'react'

export default class Intro extends Component {
	render() {
		return (
			<section className="intro">
				<h2>Velkommen til Strandengen Vels hjemmeside</h2>
			</section>
		)
	}
}
