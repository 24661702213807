import { fromJS } from 'immutable'

import { SET_KEY } from '../actions/session'

const initialState = fromJS({
	key: false,
})

function updateState(state, newProperties) {
	// console.log('new props:', JSON.stringify(newProperties));
	const value = state.merge(fromJS(newProperties))
	// console.log(value.toJS());
	return value
}

export default function session(state = initialState, action) {
	switch (action.type) {
		case SET_KEY:
			return updateState(state, {
				key: action.key,
			})

		default:
			return state
	}
}
