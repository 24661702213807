import React, { Component } from 'react'
import classNames from 'classnames'

export default class Contact extends Component {
	render() {
		const { name, contactPerson, phone1, email, address } = this.props

		const classes = classNames({
			listElement: true,
		})

		return (
			<li className={classes}>
				<h3>{name}</h3>
				<span className="contact">{contactPerson}</span>
				<span className="phone1">{phone1}</span>
				<span className="email">{email}</span>
				<span className="address">{address}</span>
			</li>
		)
	}
}
