import { hot } from 'react-hot-loader/root'
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//TODO: Do not redraw components where the properties are unchanged

import {
	setCategory,
	updateCategoryList,
	updateArticleList,
	toggleArticleVisibility,
} from '../actions/articles'
import {
	ARTICLES_PAGE,
	MEMBERS_PAGE,
	LINKS_PAGE,
	CONTACTS_PAGE,
	BOARD_PAGE,
	changeActivePage,
} from '../actions/menu'
import { updateLinks } from '../actions/links'
import { updateContacts, updateBoard } from '../actions/contacts'
import { updateMemberList, displayMemberDetails } from '../actions/members'
import { setKey } from '../actions/session'
import { setErrorMessage, clearErrorMessage } from '../actions/error'

import Header from './Header.jsx'
import ArticlesPage from './ArticlesPage.jsx'
import MembersPage from './MembersPage.jsx'
import LinksPage from './LinksPage.jsx'
import ContactsPage from './ContactsPage.jsx'
import BoardPage from './BoardPage.jsx'
import Error from './Error.jsx'
import Intro from './Intro.jsx'

class App extends Component {
	render() {
		const {
			articles,
			menu,
			links,
			contacts,
			actions,
			members,
			session,
			error,
		} = this.props

		let activePage
		switch (menu.activePage) {
			case MEMBERS_PAGE:
				activePage = (
					<MembersPage
						{...members}
						session={session}
						actions={{
							members: actions.members,
							session: actions.session,
							error: actions.error,
						}}
					/>
				)
				break
			case LINKS_PAGE:
				activePage = (
					<LinksPage
						links={links}
						actions={{
							links: actions.links,
							contacts: actions.contacts,
							error: actions.error,
						}}
					/>
				)
				break
			case CONTACTS_PAGE:
				activePage = (
					<ContactsPage
						contacts={contacts}
						actions={{
							links: actions.links,
							contacts: actions.contacts,
							error: actions.error,
						}}
					/>
				)
				break
			case BOARD_PAGE:
				activePage = (
					<BoardPage
						contacts={contacts}
						actions={{
							links: actions.links,
							contacts: actions.contacts,
							error: actions.error,
						}}
					/>
				)
				break
			case ARTICLES_PAGE:
				activePage = (
					<ArticlesPage
						{...articles}
						actions={{
							articles: actions.articles,
							error: actions.error,
						}}
					/>
				)
				break
			default:
				activePage = <Intro />
				break
		}

		return (
			<div>
				<Header
					menu={menu}
					actions={{
						menu: actions.menu,
						error: actions.error,
					}}
				/>
				<main>
					<Error {...error} />
					{activePage}
				</main>
			</div>
		)
	}
}

App.propTypes = {
	articles: propTypes.object.isRequired,
	actions: propTypes.object.isRequired,
}

function mapStateToProps(state) {
	const reducers = {}
	Object.keys(state).forEach(name => {
		reducers[name] = state[name].toJS()
	})
	return {
		...reducers,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			articles: bindActionCreators(
				{
					setCategory,
					updateCategoryList,
					updateArticleList,
					toggleArticleVisibility,
				},
				dispatch,
			),
			menu: bindActionCreators(
				{
					changeActivePage,
				},
				dispatch,
			),
			links: bindActionCreators(
				{
					updateLinks,
				},
				dispatch,
			),
			contacts: bindActionCreators(
				{
					updateContacts,
					updateBoard,
				},
				dispatch,
			),
			members: bindActionCreators(
				{
					updateMemberList,
					displayMemberDetails,
				},
				dispatch,
			),
			session: bindActionCreators(
				{
					setKey,
				},
				dispatch,
			),
			error: bindActionCreators(
				{
					setErrorMessage,
					clearErrorMessage,
				},
				dispatch,
			),
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(hot(App))
