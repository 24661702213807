import React, { Component } from 'react'

import BoardMember from './BoardMember.jsx'

export default class Board extends Component {
	render() {
		const { board } = this.props
		return (
			<section className="contacts">
				<ul>
					{board.map((contact, index) => {
						return <BoardMember key={index} {...contact} />
					})}
				</ul>
			</section>
		)
	}
}
