import React, { Component } from 'react'
import Category from './Category.jsx'
export default class Categories extends Component {
	render() {
		const { categories, categoryId } = this.props

		if (categories.length <= 0) {
			return null
		}

		return (
			<select
				className="categories"
				onChange={event => this.handleOnChange(event)}
				value={categoryId}
			>
				{categories.map(category => {
					return (
						<Category
							key={category.id}
							title={category.title}
							id={category.id}
							active={this.props.category === category.id}
						/>
					)
				})}
			</select>
		)
	}

	handleOnChange(event) {
		const { categoryId, actions } = this.props

		const newValue =
			event && event.target && event.target.value
				? event.target.value
				: categoryId
		actions.articles.setCategory(newValue)
	}
}
