import React, { Component } from 'react'

import Link from './Link.jsx'

export default class Links extends Component {
	render() {
		const { links } = this.props.links

		return (
			<section className="links">
				<ul>
					{links.map((link, index) => {
						return <Link key={index} {...link} />
					})}
				</ul>
			</section>
		)
	}
}
