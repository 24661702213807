import React, { Component } from 'react'

import Menu from './Menu.jsx'

export default class Header extends Component {
	render() {
		const { menu, actions } = this.props

		return (
			<header>
				<img className="logo" src="//arkiv.strandengenvel.no/img/header.jpg" />
				<h1>Strandengen Vel</h1>
				<Menu
					{...menu}
					actions={{
						menu: actions.menu,
						error: actions.error,
					}}
				/>
			</header>
		)
	}
}
