import React, { Component } from 'react'
import md5 from 'md5'

import load from '../services/load'

export default class Login extends Component {
	componentDidMount() {
		this.textInput.focus()
	}

	render() {
		return (
			<div className="query">
				<input
					ref={ref => (this.textInput = ref)}
					type="text"
					placeholder="Skriv inn ditt passord"
					onKeyPress={event => this.handleKeyPress(event)}
				/>
				<input
					type="button"
					onClick={event => this.handleClick(event)}
					value="OK"
				/>
			</div>
		)
	}

	handleKeyPress(event) {
		if (event.charCode == 13) {
			this.loadMembers()
		}
	}

	handleClick(event) {
		this.loadMembers()
	}

	loadMembers() {
		const { members, session, error } = this.props.actions

		const key = md5(this.textInput.value.replace(/\s+/g, ''))

		load('members', key)
			.then(result => {
				error.clearErrorMessage()
				members.updateMemberList(result)
				session.setKey(key)
			})
			.catch(err => {
				console.log('ERROR', err)

				let message
				switch (err.error) {
					case '142':
						message = 'Kunne ikke logge inn.  Brukte du riktig passord?'
						break
					default:
						message = 'En feil oppstod.  Vennligst prøv igjen senere.'
				}

				error.setErrorMessage(message)
			})
	}
}
