import { fromJS } from 'immutable'

import { UPDATE_MEMBER_LIST, DISPLAY_MEMBER_DETAILS } from '../actions/members'

const initialState = fromJS({
	status: true,
	memberList: {},
})

function updateState(state, newProperties) {
	// console.log('new props:', JSON.stringify(newProperties));
	const value = state.merge(fromJS(newProperties))
	// console.log(value.toJS());
	return value
}

export default function links(state = initialState, action) {
	const tmpList = state.toJS().memberList

	switch (action.type) {
		case UPDATE_MEMBER_LIST:
			return updateState(state, {
				memberList: action.members,
			})

		case DISPLAY_MEMBER_DETAILS:
			tmpList[action.memberId].showDetails = !tmpList[action.memberId]
				.showDetails
			return updateState(state, {
				memberList: tmpList,
			})

		default:
			return state
	}
}
