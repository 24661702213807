import React, { Component } from 'react'

import load from '../services/load'

import Board from './Board.jsx'

export default class BoardPage extends Component {
	componentDidMount() {
		const { links, contacts, error } = this.props.actions

		if (this.props.contacts.contacts.length > 0) {
			return
		}

		load('useful')
			.then(result => {
				links.updateLinks(result.links)
				contacts.updateContacts(result.contacts)
				contacts.updateBoard(result.board)
			})
			.catch(err => {
				console.log('ERROR', err)
				error.setErrorMessage(
					'Beklager. Det skjedde en feil når innholded skulle lastes inn',
				)
			})
	}

	render() {
		const {
			contacts: { board },
		} = this.props

		return <Board board={board} />
	}
}
