import React, { Component } from 'react'

export default class MemberDetails extends Component {
	render() {
		const {
			homeAdr,
			phone1,
			phone2,
			phone3,
			email1,
			email2,
			email3,
		} = this.props

		return (
			<div className="details">
				<ul>
					<li>{homeAdr}</li>
					<li>{phone1}</li>
					<li>{phone2}</li>
					<li>{phone3}</li>
					<li>{email1}</li>
					<li>{email2}</li>
					<li>{email3}</li>
				</ul>
			</div>
		)
	}
}
