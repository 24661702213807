import { loadArticles, loadMembers, loadUseful } from './loadFuncs'

export default function load(whatToLoad = null, context = null) {
	return new Promise((resolve, reject) => {
		if (whatToLoad === null) {
			reject(new Error('I do not know what to load :('))
			return
		}

		let doLoad
		switch (whatToLoad) {
			case 'useful':
				doLoad = loadUseful
				break
			case 'members':
				doLoad = loadMembers
				break
			case 'articles':
			default:
				doLoad = loadArticles
				break
		}

		doLoad(context)
			.then(result => {
				resolve(result)
			})
			.catch(err => {
				reject(err)
			})
	})
}
