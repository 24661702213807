/**
 * Actions
 */
export const UPDATE_MEMBER_LIST = 'UPDATE_MEMBER_LIST'
export const DISPLAY_MEMBER_DETAILS = 'DISPLAY_MEMBER_DETAILS'

/**
 *
 */
export function updateMemberList(members) {
	return {
		type: UPDATE_MEMBER_LIST,
		members,
	}
}

/**
 *
 */
export function displayMemberDetails(memberId) {
	return {
		type: DISPLAY_MEMBER_DETAILS,
		memberId,
	}
}
