import { fromJS } from 'immutable'

import { SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE } from '../actions/error'

const NO_ERROR_MESSAGE = false
const initialState = fromJS({
	message: NO_ERROR_MESSAGE,
})

// TODO: Make a common updateState
function updateState(state, newProperties) {
	// console.log('new props:', JSON.stringify(newProperties));
	const value = state.merge(fromJS(newProperties))
	// console.log(value.toJS());
	return value
}

export default function error(state = initialState, action) {
	switch (action.type) {
		case SET_ERROR_MESSAGE:
			return updateState(state, {
				message: action.message,
			})
		case CLEAR_ERROR_MESSAGE:
			return updateState(state, {
				message: NO_ERROR_MESSAGE,
			})
		default:
			return state
	}
}
