import { fromJS } from 'immutable'

import {
	CHANGE_ARTICLE_CATEGORY,
	UPDATE_ARTICLES,
	UPDATE_CATEGORIES,
	TOGGLE_ARTICLE_VISIBILITY,
} from '../actions/articles'

const initialState = fromJS({
	categoryId: 0,
	categoryList: [],
	articleList: [],
	allArticles: [],
	numArticlesInCategory: 0,
	numArticles: 0,
})

// eslint-disable-next-line no-shadow
function filterArticleList(articles, categoryId) {
	if (Number(categoryId) === 0) {
		return articles
	}

	return articles.filter(article => article.catId === categoryId)
}

function updateState(state, newProperties) {
	return state.merge(fromJS(newProperties))
}

export default function articles(state = initialState, action) {
	const allArticles = action.articles
		? action.articles
		: state.toJS().allArticles
	const categoryId = action.categoryId
		? action.categoryId
		: state.toJS().categoryId
	const articlesToView = filterArticleList(allArticles, Number(categoryId))

	const { categories } = action
	const currentlyViewableArticles = state.toJS().articleList

	switch (action.type) {
		case CHANGE_ARTICLE_CATEGORY:
			return updateState(state, {
				categoryId: action.categoryId,
				articleList: articlesToView,
				numArticlesInCategory: articlesToView.length,
			})

		case UPDATE_ARTICLES:
			return updateState(state, {
				articleList: articlesToView,
				allArticles: action.articles,
				numArticlesInCategory: articlesToView.length,
				numArticles: action.articles.length,
			})

		case UPDATE_CATEGORIES:
			categories.forEach((cat, i) => {
				// remove invalid categories
				if (Number.isNaN(cat.id) || typeof cat.title !== 'string') {
					categories.splice(i, 1)
				}
			})
			return updateState(state, {
				categoryList: categories,
			})

		case TOGGLE_ARTICLE_VISIBILITY:
			currentlyViewableArticles[
				action.articleId
			].visible = !currentlyViewableArticles[action.articleId].visible
			return updateState(state, {
				articleList: currentlyViewableArticles,
			})

		default:
			return state
	}
}
