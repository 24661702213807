import React, { Component } from 'react'
import classNames from 'classnames'

export default class Link extends Component {
	render() {
		const { title, description, url } = this.props

		const classes = classNames({
			listElement: true,
		})

		return (
			<li className={classes}>
				<button className="link" />
				<a href={url} target="blank">
					<h3>{title}</h3>
					<span className="description">{description}</span>
				</a>
			</li>
		)
	}
}
