import React, { Component } from 'react'

import Contact from './Contact.jsx'

export default class Contacts extends Component {
	render() {
		const { contacts } = this.props
		return (
			<section className="contacts">
				<ul>
					{contacts.map((contact, index) => {
						return <Contact key={index} {...contact} />
					})}
				</ul>
			</section>
		)
	}
}
