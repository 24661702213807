import React, { Component } from 'react'
import classNames from 'classnames'

export default class BoardMember extends Component {
	render() {
		const { name, contactPerson: title, phone1, email } = this.props

		const classes = classNames({
			listElement: true,
		})

		return (
			<li className={classes}>
				<h2>{title}:</h2>
				<h3>{name}</h3>
				<span className="phone1">{phone1}</span>
				<span className="email">{email}</span>
			</li>
		)
	}
}
