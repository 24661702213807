import React, { Component } from 'react'

export default class Error extends Component {
	render() {
		const { message } = this.props

		if (!message) {
			return null
		}

		return (
			<section className="error">
				<h2>Feilmelding</h2>
				<span>{message}</span>
			</section>
		)
	}
}
