import React, { Component } from 'react'

import Login from './Login.jsx'
import Members from './Members.jsx'

export default class MembersPage extends Component {
	render() {
		const {
			memberList,
			session,
			actions,
			displayDetailsForMemberId,
		} = this.props

		// TODO: Add search

		let activeComponent
		if (!session.key) {
			activeComponent = <Login actions={actions} />
		} else {
			activeComponent = (
				<Members
					memberList={memberList}
					displayDetailsForMemberId={displayDetailsForMemberId}
					actions={actions.members}
				/>
			)
		}

		return <section className="members">{activeComponent}</section>
	}
}
