import React, { Component } from 'react'
import classNames from 'classnames'

export default class Menu extends Component {
	render() {
		const { activePage, pages } = this.props

		const navClasses = classNames({
			bigmenu: activePage === null,
		})

		return (
			<nav className={navClasses}>
				<ul>
					{Object.keys(pages).map(key => {
						const itemClasses = classNames(pages[key].className, {
							active: activePage === key,
						})
						return (
							<li
								key={key}
								className={itemClasses}
								onClick={event => this.handleClick(event, key)}
							>
								{pages[key].title}
							</li>
						)
					})}
				</ul>
			</nav>
		)
	}

	handleClick(event, key) {
		const { activePage, actions } = this.props

		if (activePage !== key) {
			actions.menu.changeActivePage(key)
			actions.error.clearErrorMessage()
		}
	}
}
