import sanitizeHtml from 'sanitize-html'

const unwantedCategoryIds = [5, 6, 7]

export function prepareArticle(articleParam) {
	const article = articleParam
	const allowedTags = {
		allowedTags: ['strong', 'a', 'p', 'div'],
		allowedAttributes: {
			a: ['href'],
		},
		selfClosing: ['br'],
	}

	article.description = sanitizeHtml(article.description, allowedTags)
	article.body = sanitizeHtml(article.body, allowedTags)
	article.visible = false

	article.body = article.body.replace(
		/<a href="..\/pdf\//gm,
		'<a href="//arkiv.strandengenvel.no/pdf/',
	)

	if (unwantedCategoryIds.includes(Number(article.catId))) {
		article.catId = 1 // catId 1 is 'Generell Informasjon'
	}
}

export function prepareCategories(categories) {
	const defaultCategory = {
		id: 0,
		title: 'Alle tema',
	}

	const wantedCategories = categories.filter(
		category => !unwantedCategoryIds.includes(Number(category.id)),
	)

	wantedCategories.unshift(defaultCategory)
	return wantedCategories
}
