/**
 * Actions
 */
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS'
export const UPDATE_BOARD = 'UPDATE_BOARD'

/**
 *
 */
export function updateContacts(contacts) {
	return {
		type: UPDATE_CONTACTS,
		contacts,
	}
}

/**
 *
 */
export function updateBoard(board) {
	return {
		type: UPDATE_BOARD,
		board,
	}
}
