import React from 'react' // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux' // eslint-disable-line no-unused-vars

import App from './components/App.jsx' // eslint-disable-line import/extensions, no-unused-vars
import './styles/index.scss'
import configureStore from './store/configureStore'

const store = configureStore({})
store.subscribe(() => {
	const state = store.getState()
	const obj = {}
	Object.keys(state).forEach(key => {
		obj[key] = state[key].toJS()
	})
	// eslint-disable-next-line no-undef
	if (__DEVELOPMENT__) {
		// const util = require('util');
		// console.log('store-log:', util.inspect(obj, false, null));
		console.log('store-log:', obj) // eslint-disable-line no-console
	}
})

function renderApp() {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.querySelector('#app'), // eslint-disable-line no-undef
	)
}

/**
 * App
 */
document.addEventListener('DOMContentLoaded', () => {
	// eslint-disable-line no-undef
	renderApp()
})
