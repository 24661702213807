/**
 * Actions
 */
export const UPDATE_LINKS = 'UPDATE_LINKS'

/**
 *
 */
export function updateLinks(links) {
	return {
		type: UPDATE_LINKS,
		links,
	}
}
