import React, { Component } from 'react'
import classNames from 'classnames'

import MemberDetails from './MemberDetails.jsx'

export default class Member extends Component {
	render() {
		const { name, otherPersons, cabinAdr, showDetails } = this.props

		const classes = classNames({
			member: true,
			listElement: true,
			visible: showDetails,
		})

		return (
			<li className={classes} onClick={event => this.handleClick(event)}>
				<button className="expand" />
				<ul>
					<li className="name">
						<h3>{name}</h3>
					</li>
					<li className="otherPersons">{otherPersons}</li>
					<li className="cabin">{cabinAdr}</li>
					{showDetails && <MemberDetails {...this.props} />}
				</ul>
			</li>
		)
	}

	handleClick(event) {
		const { actions, id } = this.props

		actions.displayMemberDetails(id)
	}
}
