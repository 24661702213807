import React, { Component } from 'react'
import classNames from 'classnames'

import { formatDate } from '../helpers'

export default class Article extends Component {
	handleClick(event) {
		const { actions, index } = this.props

		if (event.target.nodeName.toLowerCase() === 'a') {
			event.target.target = '_blank'
			return
		}

		actions.articles.toggleArticleVisibility(index)
	}

	render() {
		const { title, description, body, published, visible } = this.props

		const articleClasses = classNames({
			listElement: true,
			visible: visible,
		})

		const publishedDate = formatDate(published)

		return (
			<article
				className={articleClasses}
				onClick={event => this.handleClick(event)}
			>
				<button className="expand" />
				<h3>{title}</h3>
				<time>{publishedDate}</time>
				<div className="content">
					<span
						className="description"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
					<span className="body" dangerouslySetInnerHTML={{ __html: body }} />
				</div>
			</article>
		)
	}
}

Article.defaultProps = {
	title: '',
	description: '',
	body: '',
}
