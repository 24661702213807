import { combineReducers } from 'redux'

import articles from './articles'
import menu from './menu'
import links from './links'
import contacts from './contacts'
import members from './members'
import session from './session'
import error from './error'

const rootReducer = combineReducers({
	articles,
	menu,
	links,
	contacts,
	members,
	session,
	error,
})

export default rootReducer
