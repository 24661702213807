import React, { Component } from 'react'

import load from '../services/load'

import Links from './Links.jsx'

export default class LinksPage extends Component {
	componentDidMount() {
		const { links, contacts, error } = this.props.actions

		if (this.props.links.links.length > 0) {
			return
		}

		load('useful')
			.then(result => {
				links.updateLinks(result.links)
				contacts.updateContacts(result.contacts)
				contacts.updateBoard(result.board)
			})
			.catch(err => {
				console.log('ERROR', err)
				error.setErrorMessage(
					'Beklager. Det skjedde en feil når innholded skulle lastes inn',
				)
			})
	}

	render() {
		const { links } = this.props

		return <Links links={links} />
	}
}
