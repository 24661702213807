import axios from 'axios'

import { sortMemberList } from '../helpers'

import { prepareArticle, prepareCategories } from './prepareFuncs'

const BASE_URL = process.env.API_BASE_URL

const axiosConfig = {
	auth: {
		username: process.env.API_AUTH_USERNAME,
		password: process.env.API_AUTH_PASSWORD,
	},
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	},
}

export function loadMembers(password) {
	const url = `${BASE_URL}/members`
	const data = {
		passop: password,
	}
	return new Promise((resolve, reject) => {
		axios
			.post(url, data, axiosConfig)
			.then(response => {
				if (response.data.error) {
					reject(response.data)
					return
				}
				resolve(sortMemberList(response.data))
			})
			.catch(err => {
				reject(err)
			})
	})
}

export function loadUseful() {
	return new Promise((resolve, reject) => {
		const url = `${BASE_URL}/links`
		axios
			.get(url, axiosConfig)
			.then(response => {
				const { contacts, links, board, error } = response.data

				if (typeof error !== 'undefined') {
					reject(error)
					return
				}

				resolve({
					contacts,
					links,
					board,
				})
			})
			.catch(err => {
				reject(err)
			})
	})
}

export function loadArticles() {
	return new Promise((resolve, reject) => {
		const url = `${BASE_URL}/info`
		axios
			.get(url, axiosConfig)
			.then(response => {
				const { categories, articles, error } = response.data

				if (typeof error !== 'undefined') {
					reject(error)
					return
				}

				articles.forEach(article => {
					prepareArticle(article)
				})

				resolve({
					articles,
					categories: prepareCategories(categories),
				})
			})
			.catch(err => {
				reject(err)
			})
	})
}
