/**
 * Actions
 */
export const SET_KEY = 'SET_KEY'

/**
 *
 */
export function setKey(key) {
	return {
		type: SET_KEY,
		key,
	}
}
