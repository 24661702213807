import { fromJS } from 'immutable'

import { UPDATE_CONTACTS, UPDATE_BOARD } from '../actions/contacts'

const initialState = fromJS({
	contacts: [],
	board: [],
})

function updateState(state, newProperties) {
	// console.log('new props:', JSON.stringify(newProperties));
	const value = state.merge(fromJS(newProperties))
	// console.log(value.toJS());
	return value
}

export default function contacts(state = initialState, action) {
	switch (action.type) {
		case UPDATE_CONTACTS:
			return updateState(state, {
				contacts: action.contacts,
			})

		case UPDATE_BOARD:
			return updateState(state, {
				board: action.board,
			})

		default:
			return state
	}
}
