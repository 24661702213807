import React, { Component } from 'react'
import classNames from 'classnames'

import Member from './Member.jsx'

export default class Members extends Component {
	render() {
		const { memberList, actions } = this.props

		const memberIds = Object.keys(memberList)

		if (memberIds.length <= 0) {
			return null
		}

		const classes = classNames('members')

		return (
			<div className={classes}>
				<ul>
					{memberIds.map(id => {
						return (
							<Member key={id} id={id} {...memberList[id]} actions={actions} />
						)
					})}
				</ul>
			</div>
		)
	}
}
