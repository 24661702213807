import { fromJS } from 'immutable'

import { UPDATE_LINKS } from '../actions/links'

const initialState = fromJS({
	links: [],
})

function updateState(state, newProperties) {
	// console.log('new props:', JSON.stringify(newProperties));
	const value = state.merge(fromJS(newProperties))
	// console.log(value.toJS());
	return value
}

export default function links(state = initialState, action) {
	switch (action.type) {
		case UPDATE_LINKS:
			return updateState(state, {
				links: action.links,
			})

		default:
			return state
	}
}
