import { fromJS } from 'immutable'

import { CHANGE_ACTIVE_PAGE } from '../actions/menu'

const initialState = fromJS({
	activePage: null,
	pages: {
		ARTICLES_PAGE: { title: 'Informasjon', className: 'info' },
		MEMBERS_PAGE: { title: 'Medlemmer', className: 'members' },
		LINKS_PAGE: { title: 'Lenker', className: 'links' },
		CONTACTS_PAGE: { title: 'Kontakter', className: 'contacts' },
		BOARD_PAGE: { title: 'Styret', className: 'contacts' },
	},
})

function updateState(state, newProperties) {
	// console.log('new props:', JSON.stringify(newProperties));
	const value = state.merge(fromJS(newProperties))
	// console.log(value.toJS());
	return value
}

export default function menu(state = initialState, action) {
	switch (action.type) {
		case CHANGE_ACTIVE_PAGE:
			if (Object.keys(state.toJS().pages).indexOf(action.page) < 0) {
				return state
			}
			return updateState(state, {
				activePage: action.page,
			})

		default:
			return state
	}
}
